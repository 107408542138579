import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";

function App() {
  var Airtable = require("airtable");

  try {
    var base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_TOKEN,
    }).base("appyOmnMx1ZcwbUA5");
  } catch (error) {
    console.log(error);
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home base={base} />} />
      </Routes>
    </Router>
  );
}

export default App;
