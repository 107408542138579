import React, { useState } from "react";
import Title from "../images/title.png";

const Home = ({ base }) => {
  const [christmasList, setChristmasList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPricePerBuyer, setTotalPricePerBuyer] = useState([
    { name: "Paul", price: 0 },
    { name: "Marie", price: 0 },
    { name: "Nelson", price: 0 },
    { name: "Maureen", price: 0 },
    { name: "Laetitia", price: 0 },
    { name: "Alexandre", price: 0 },
    { name: "Sunil", price: 0 },
  ]);

  const checkIfItemAlreadyExists = (item) => {
    var exists = false;
    christmasList.forEach((element) => {
      if (element.product === item.product) {
        exists = true;
      }
    });
    return exists;
  };

  const retreiveData = () => {
    console.log("retreiveData");
    try {
      base("noel")
        .select({
          // Selecting the first 3 records in Grid view:
          maxRecords: 3,
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              console.log(record);
              if (checkIfItemAlreadyExists(record.get("Product"))) {
                console.log("item already exists");
                return;
              } else {
                if (record.get("Real") === true) {
                  setChristmasList((christmasList) => [
                    ...christmasList,
                    {
                      id: record.getId(),
                      product: record.get("Product"),
                      quantity: record.get("Quantity"),
                      unit: record.get("Unit"),
                      price: record.get("Price"),
                      buyer: record.get("Buyer"),
                    },
                  ]);
                }
              }
            });

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    } catch {
      console.log("error");
    }
  };

  const AddItem = () => {
    var product = document.getElementById("product").value;
    product = product.charAt(0).toUpperCase() + product.slice(1);
    var quantity = document.getElementById("quantity").value;
    quantity = parseFloat(quantity.replace(".", ",")).toFixed(2);
    quantity = quantity.replace(/\s/g, "");
    quantity = quantity.replace(/[^0-9.]/g, "");
    quantity = quantity.replace(" ", "");
    var unit = document.getElementById("unit").value;
    var price = document.getElementById("price").value;
    price = parseFloat(price.replace(".", ",")).toFixed(2);
    price = price.replace(/\s/g, "");
    price = price.replace(/[^0-9.]/g, "");
    price = price.replace(" ", "");
    var buyer = document.getElementById("buyer").value;

    // check if all fields are filled
    if (
      product === "" ||
      quantity === "" ||
      unit === "" ||
      price === "" ||
      buyer === ""
    ) {
      console.log("empty field");
      return;
    }

    var item = {
      id: "",
      product: product,
      quantity: quantity,
      unit: unit,
      price: price,
      buyer: buyer,
    };
    if (checkIfItemAlreadyExists(item)) {
      console.log("item already exists");
      return;
    } else {
      setChristmasList((christmasList) => [...christmasList, item]);
      base("noel").create(
        [
          {
            fields: {
              Product: product,
              Quantity: quantity,
              Unit: unit,
              Price: price,
              Buyer: buyer,
              Real: true,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.error(err);
            return;
          }
          records.forEach(function (record) {
            console.log(record.getId());
            item.id = record.getId();
          });
        }
      );
      CalculateTotalPrice();
    }
  };

  const deleteItem = (e) => {
    var product = e.target.parentNode.parentNode.childNodes[0].innerHTML;
    var buyer = e.target.parentNode.parentNode.childNodes[3].innerHTML;
    console.log("Product: " + product);
    console.log("Buyer: " + buyer);

    var index = 0;

    for (var i = 0; i < christmasList.length; i++) {
      console.log(christmasList[i].product);
      console.log(christmasList[i].buyer);
      if (
        christmasList[i].product === product &&
        christmasList[i].buyer === buyer
      ) {
        index = i;
      }
    }

    console.log(christmasList[index].id);

    // Update the price to 0 on airtable
    base("noel").update(
      [
        {
          id: christmasList[index].id,
          fields: {
            Real: false,
          },
        },
      ],
      function (err) {
        if (err) {
          console.error(err);
          return;
        }
      }
    );

    var newChristmasList = christmasList.filter(
      (item) => item.product !== product || item.buyer !== buyer
    );
    setChristmasList(newChristmasList);
    CalculateTotalPrice();
  };

  const CalculateTotalPrice = () => {
    var totalPricee = 0;
    christmasList.forEach((item) => {
      var price = parseFloat(item.price.replace(",", ".").replace(" ", ""));
      totalPricee += price;
    });
    setTotalPrice(totalPricee);

    var totalPricePerBuyerr = totalPricePerBuyer;
    var pricePerPerson = totalPricee / totalPricePerBuyerr.length;

    totalPricePerBuyerr.forEach((item) => {
      item.price = pricePerPerson;
    });

    christmasList.forEach((item) => {
      var buyer = item.buyer;
      var price = parseFloat(item.price.replace(",", ".").replace(" ", ""));
      var index = totalPricePerBuyerr.findIndex((x) => x.name === buyer);
      totalPricePerBuyerr[index].price -= price;
    });
    setTotalPricePerBuyer(totalPricePerBuyerr);
  };

  return (
    <div onLoad={retreiveData}>
      <h1>Our Christmas list</h1>
      <img src={Title} alt="title" className="title" />
      <div className="main-container">
        <div className="container">
          {christmasList.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Buyer</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {christmasList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.product}</td>
                    <td>
                      {item.quantity} {item.unit}
                    </td>
                    <td className="nowrap">
                      {item.price === "0.00" ? (
                        "Free ♥"
                      ) : (
                        <span>{item.price} €</span>
                      )}
                    </td>
                    <td>{item.buyer}</td>
                    <td>
                      <button className="delete" onClick={deleteItem}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            // Spinner
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
        </div>
        <div className="container">
          <input type="text" placeholder="Product" id="product" />
          <input type="number" placeholder="Quantity" id="quantity" min={0} />
          <input type="text" placeholder="Unit" id="unit" />
          <input type="number" placeholder="Price" id="price" min={0} />
          <select id="buyer">
            <option value="none" selected disabled hidden>
              Buyer
            </option>
            <option value="Alexandre">Alexandre</option>
            <option value="Laetitia">Laetitia</option>
            <option value="Maureen">Maureen</option>
            <option value="Marie">Marie</option>
            <option value="Nelson">Nelson</option>
            <option value="Paul">Paul</option>
            <option value="Sunil">Sunil</option>
          </select>
          <button onClick={AddItem}>Add item</button>
        </div>
        <div className="container gap17">
          <button onClick={CalculateTotalPrice}>Refresh</button>
          {
            <div className="total-price">
              Total price: {totalPrice.toFixed(2)} €
            </div>
          }
          {
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {totalPricePerBuyer.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td className="nowrap">{item.price.toFixed(2)} €</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </div>
  );
};

export default Home;
